<template>
  <div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead">
        <vs-th>
          <vs-checkbox color="success" v-model="selectAll">
            Check All
          </vs-checkbox>
        </vs-th>
      </template>
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.creditNotes">
          <vs-td>
            <vs-checkbox
              color="success"
              :checked="isChecked(tr.ID)"
              @click="checkboxHandle(tr.ID)"
            >
            </vs-checkbox>
          </vs-td>
          <vs-td>
            {{ tr.Code }}
          </vs-td>
          <vs-td>
            Code : {{ tr.CustomerCode }}<br />
            Name : {{ tr.CustomerName }}<br />
          </vs-td>
          <vs-td>
            {{ dateFormat(tr.CreatedAt) }}
          </vs-td>
          <vs-td>
            {{ priceFormat(tr.TotalValue - tr.UsedValue) }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import { creditNoteArClearing } from "../../../../../services/api/credit_note";
import moment from "moment";

export default {
  props: {
    isReload: Boolean,
  },
  created() {
    this.$store.watch(
      (state) => state.arClearing.selectedCustomer,
      (newVal) => {
        this.params.customer_id = newVal.ID;
      }
    );
  },
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
      this.$emit("setReload", false);
    },
    selectAll() {
      if (this.selectAll) {
        console.log("all checked");
        // creditNotes = [...this.responseData.creditNotes];
        this.$store.commit("arClearing/setCreditNote", [
          ...this.responseData.creditNotes,
        ]);
      } else {
        console.log("all unchecked");
        this.$store.commit("arClearing/setCreditNote", []);
      }
      // const creditNotes = this.$store.state.arClearing.selectedCreditNote;
      // console.log(creditNotes);
    },
  },
  data() {
    const selectedCustomer = this.$store.state.arClearing.selectedCustomer;
    return {
      isViewRendered: false,
      selectAll: false,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "credit_notes.id",
        is_ar_clearing: true,
        customer_id: selectedCustomer === null ? 0 : selectedCustomer.ID,
        status: 4,
      },
      header: [
        {
          text: "Code",
          value: "credit_notes.code",
        },
        {
          text: "Customer",
          value: "credit_notes.customer_name",
          // width: '5%'
        },
        {
          text: "Date",
          sortable: false,
          // width: '5%'
        },
        {
          text: "Value",
        },
      ],
      responseData: {},
    };
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("MM/DD/YYYY hh:mm");
      }
    },
    reloadData(params) {
      const newParams = { ...this.params };
      newParams.page = params.page;
      newParams.search = params.search;
      this.$vs.loading();

      const creditNotes = creditNoteArClearing(newParams);
      creditNotes.then((r) => {
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          console.log("resp", this.responseData);
          this.$vs.loading.close();
          this.responseData = r.data;
          this.responseData.length = r.data.length;
          this.responseData.recordsTotal = r.data.recordsTotal;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    checkboxHandle(id) {
      const cn = this.responseData.creditNotes.find((cn) => cn.ID === id);
      const creditNotes = JSON.parse(
        JSON.stringify(this.$store.state.arClearing.selectedCreditNote)
      );
      if (creditNotes.length === 0) {
        creditNotes.push(cn);
        this.$store.commit("arClearing/setCreditNote", creditNotes);
        console.log(creditNotes)
        return;
      }

      if (creditNotes.some((cn) => cn.ID === id)) {
        const index = creditNotes.findIndex((cn) => cn.ID === id);
        creditNotes.splice(index, 1);
      } else {
        creditNotes.push(cn);
      }
      this.$store.commit("arClearing/setCreditNote", creditNotes);
    },
    isChecked(id) {
      //   console.log(creditNotes);
      const creditNotes = this.$store.state.arClearing.selectedCreditNote;
      if (creditNotes === 0) {
        return false;
      }
      console.log(creditNotes.some((cn) => cn.ID === id));
      return creditNotes.some((cn) => cn.ID === id);
    },
    // checkAll() {
    //   this.$nextTick(() => {
    //     console.log("select All func", this.selectAll);
    //     if (this.selectAll) {
    //       console.log("all checked");
    //       creditNotes = [...this.responseData.creditNotes];
    //     } else {
    //       console.log("all unchecked");
    //       creditNotes = [];
    //     }
    //   });
    // },
  },
};
</script>
