<template>
  <div class="flex flex-col w-full gap-3">
    <div
      class="flex md:flex-row w-full items-center h-full sm:flex-col sm:items-start sm:gap-4"
    >
      <customer-selection />
      <vs-spacer />
      <cn-deposit-selection />
    </div>
    <div class="flex flex-row justify-start w-full gap-3">
      <vs-button
        type="border"
        class="mt-1 mb-4"
        style="height: 40px"
        @click="onShow"
        >Show</vs-button
      >
      <vs-button class="mt-1 mb-4" style="height: 40px" @click="onNavigate"
        >Next</vs-button
      >
    </div>
    <div v-if="this.$store.state.arClearing.isDataVisible">
      <cn-data-view
        v-if="this.$store.state.arClearing.radioFilterValue === 'cn'"
        :isReload="isReload"
        @setReload="setReload"
      />
      <cd-data-view
        v-else-if="this.$store.state.arClearing.radioFilterValue === 'cd'"
        :isReload="isReload"
        @setReload="setReload"
      />
    </div>
  </div>
</template>
<script>
import CdDataview from "./cd-dataview.vue";
import cnDataview from "./cn-dataview.vue";
import CnDepositSelection from "./cn-deposit-selection.vue";
import customerSelection from "./customer-selection.vue";
export default {
  components: {
    "customer-selection": customerSelection,
    "cn-deposit-selection": CnDepositSelection,
    "cn-data-view": cnDataview,
    "cd-data-view": CdDataview,
  },
  data() {
    return {
      isReload: false,
    };
  },
  methods: {
    onShow() {
      const selectedCustomer = this.$store.state.arClearing.selectedCustomer;
      if (Object.keys(selectedCustomer).length === 0) {
        this.$vs.notify({
          title: "AR Clearing",
          text: "Please select a customer",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      const isVisible = this.$store.state.arClearing.isDataVisible;
      if (!isVisible) {
        this.$store.commit("arClearing/setDatavisibility", true);
        return;
      }
      this.isReload = true;
    },
    setReload(val) {
      this.isReload = val;
    },
    onNavigate() {
      const radioValue = this.$store.state.arClearing.radioFilterValue;
      const selected =
        radioValue === "cn"
          ? this.$store.state.arClearing.selectedCreditNote
          : this.$store.state.arClearing.selectedCustomerDeposit;
      if (selected.length === 0) {
        const item = radioValue === "cn" ? "Credit Note" : "Customer Deposit";
        this.$vs.notify({
          title: "AR Clearing",
          text: `${item} must be selected first`,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      this.$store.commit("arClearing/setTabIndex", 1);
    },
  },
};
</script>
