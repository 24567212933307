<template>
  <vx-card title="AR Clearing">
    <div class="flex flex-col w-full gap-3">
      <!-- <div
        class="flex md:flex-row w-full items-center h-full sm:flex-col sm:items-start sm:gap-4"
      >
        <customer-selection
          :selectedCustomer="selectedCustomer"
          @setSelectedCustomer="setSelectedCustomer"
        />
        <vs-spacer />
        <cn-deposit-selection />
      </div>
      <div class="flex flex-row justify-start w-full">
        <vs-button class="mt-4 mb-4" style="height: 40px" @click="onShow"
          >Show</vs-button
        >
      </div>
      <vs-divider v-if="this.isVisible" />
      <date-pickers v-if="tabIndex === 1" />
      <div
        class="flex flex-col"
        v-if="
          selectedCustomer !== null && selectedCheckbox !== null && isVisible
        "
      >
        <div class="w-full flex justify-start mt-3 gap-3">
          <vs-button @click="onNavigate">
            <div v-if="tabIndex === 0">Next</div>
            <div v-else-if="tabIndex === 1">Previous</div>
          </vs-button>
          <vs-button v-if="tabIndex === 1" @click="onNavigate">
            Clear
          </vs-button>
        </div>
        <vs-tabs :value="tabIndex" :key="tabKey">
          <vs-tab :label="tabName" ref="selectTab">
            <div class="con-tab-ejemplo pt-4">
              <cn-data-view
                v-if="this.$store.state.arClearing.radioFilterValue === 'cn'"
                :selectedCheckbox="selectedCheckbox"
                :selectedCustomer="selectedCustomer"
                :isReload="isReload"
                @setReload="setReload"
              />
              <cd-dataview
                v-else-if="
                  this.$store.state.arClearing.radioFilterValue === 'cd'
                "
                :selectedCheckbox="selectedCheckbox"
                :selectedCustomer="selectedCustomer"
                :isReload="isReload"
                @setReload="setReload"
              />
            </div>
          </vs-tab>

          <vs-tab label="Invoice" ref="finalTab">
            <div class="con-tab-ejemplo pt-4">
              <invoice-data-view :selectedCustomer="selectedCustomer" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div> -->
      <!-- <div class="w-full flex justify-start mt-3 gap-3">
        <vs-button @click="onNavigate">
          <div v-if="tabIndex === 0">Next</div>
          <div v-else-if="tabIndex === 1">Previous</div>
        </vs-button>
        <vs-button v-if="tabIndex === 1" @click="onNavigate"> Clear </vs-button>
      </div> -->

      <vs-tabs :value="this.$store.state.arClearing.tabIndex">
        <vs-tab label="Credit Note / Customer Deposit">
          <div class="con-tab-ejemplo pt-4">
            <cn-dn-select />
          </div>
        </vs-tab>
        <vs-tab label="Clearing">
          <div class="con-tab-ejemplo pt-4">
            <clearing />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>

<script>
import clearing from "./clearing/clearing.vue";
import cnDnSelectVue from "./cn_dn_select/cn-dn-select.vue";

export default {
  components: {
    "cn-dn-select": cnDnSelectVue,
    clearing,
  },
  // destroyed() {
  //   this.$store.commit("arClearing/resetState");
  // },
};
</script>

<style scope>
.vs-tabs--li {
  pointer-events: none;
}
</style>
