<template>
  <div class="w-2/5 flex flex-col gap-3">
    <label class="text-lg" for="">Credit Note / Customer Deposit</label>

    <div class="flex flex-col gap-3 w-full items-start font-bold">
      <vs-radio v-model="radioValue" vs-name="radios1" vs-value="cn"
        >Credit Note</vs-radio
      >
      <vs-radio v-model="radioValue" vs-name="radios1" vs-value="cd"
        >Customer Deposit</vs-radio
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // checkboxValue: String,
  },
  watch: {
    radioValue() {
      if (this.radioValue === "cn") {
        const customerDeposit =
          this.$store.state.arClearing.selectedCustomerDeposit;
        if (customerDeposit.length > 0) {
          this.$store.commit("arClearing/resetCustomerDeposit");
        }
      } else {
        const creditNote = this.$store.state.arClearing.selectedCreditNote;
        if (creditNote.length > 0) {
          this.$store.commit("arClearing/resetCreditNote");
        }
      }
      this.$store.commit("arClearing/setRadioFilter", this.radioValue);
    },
  },
  data() {
    return {
      radioValue: this.$store.state.arClearing.radioFilterValue,
    };
  },
  methods: {},
  mounted(){

  }
};
</script>
