<template>
  <div class="flex flex-col gap-3 min-w-1/5 w-2/5 sm:w-3/4">
    <label for="customer" class="text-lg">Customers</label>
    <multiselect
      :value="this.$store.state.arClearing.selectedCustomer"
      id="customer"
      :options="customerOptions"
      :multiple="false"
      :allow-empty="true"
      :group-select="false"
      :max-height="200"
      :limit="3"
      placeholder="Select customer"
      track-by="code"
      :custom-label="queryCustomer"
      @search-change="onSearchChange"
      @input="onCustomerChange"
    >
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">
            <span class="option__title"
              >({{ props.option.code }}) {{ props.option.name }}</span
            >
          </span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: {},
  components: {
    multiselect: Multiselect,
  },
  created() {
    this.$store.watch(
      (state) => state.arClearing.selectedCustomer,
      () => {
        this.$store.commit("arClearing/resetSelectedCnDn");
      }
    );
    this.debouncedSearch = this.debounce(async () => {
      try {
        this.$vs.loading();
        const resp = await this.fetchCustomer(this.search);
        console.log(resp);
        this.customerOptions = resp.data;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "AR Clearing",
          text: e.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "error",
        });
      }
    }, 300);
  },
  mounted() {
    this.onLoad();
  },
  data() {
    return {
      none: { id: 0, name: "Customer", code: "-" },
      customerOptions: [],
      debouncedSearch: null,
      search: "",
    };
  },
  methods: {
    queryCustomer({ name, code }) {
      if (!name || !code) {
        return "Please select customer";
      } else {
        return `(${code}) - ${name}`;
      }
    },

    fetchCustomer(search) {
      return this.$http.get("/api/v1/master/customer-filter", {
        params: {
          search,
        },
      });
    },
    async onLoad() {
      try {
        this.$vs.loading();
        const resp = await this.fetchCustomer("");
        console.log(resp);
        this.customerOptions = [...this.customerOptions, ...resp.data];
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "AR Clearing",
          text: e.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "error",
        });
      }
    },
    debounce(fn, wait) {
      let timer;
      return function (...args) {
        if (timer) {
          clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
          fn.apply(context, args); // call the function if time expires
        }, wait);
      };
    },
    async onSearchChange(searchQuery) {
      this.search = searchQuery;
      this.debouncedSearch();
    },
    onCustomerChange(customer) {
      // this.$emit("setSelectedCustomer", customer);
      // this.$store.state.arClearing.selectedCustomer;
      this.$store.commit("arClearing/setCustomer", customer);
      console.log(this.$store.state.arClearing.selectedCustomer);
    },
  },
};
</script>
