<template>
  <div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead">
        <vs-th>
          <vs-checkbox color="success" v-model="selectAll">
            Check All
          </vs-checkbox>
        </vs-th>
      </template>
      <template slot="tbody">
        <vs-tr
          :key="indextr"
          v-for="(tr, indextr) in responseData.customerDeposits"
        >
          <vs-td>
            <vs-checkbox
              color="success"
              :checked="isChecked(tr.ID)"
              @click="checkboxHandle(tr.ID)"
            >
            </vs-checkbox>
          </vs-td>
          <vs-td>
            {{ tr.Code }}
          </vs-td>
          <vs-td>
            Code : {{ tr.PersonCode }}<br />
            Name : {{ tr.PersonName }}<br />
          </vs-td>
          <vs-td>
            {{ dateFormat(tr.CreatedAt) }}
          </vs-td>
          <vs-td>
            {{ priceFormat(tr.DepositValue) }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    isReload: Boolean,
  },
  created() {
    this.$store.watch(
      (state) => state.arClearing.selectedCustomer,
      (newVal) => {
        this.params.person_id = newVal.ID;
        this.params.person_code = newVal.code;
      }
    );
  },
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
      this.$emit("setReload", false);
    },
    selectAll() {
      if (this.selectAll) {
        console.log("all checked");
        // this.selectedCreditNotes = [...this.responseData.customerDeposits];
        this.$store.commit("arClearing/setCustomerDeposit", [
          ...this.responseData.customerDeposits,
        ]);
      } else {
        console.log("all unchecked");
        // this.selectedCreditNotes = [];
        this.$store.commit("arClearing/setCustomerDeposit", []);
      }
    },
  },
  data() {
    const selectedCustomer = this.$store.state.arClearing.selectedCustomer;
    return {
      isViewRendered: false,
      selectedCreditNotes: [],
      selectAll: false,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "credit_notes.id",
        is_ar_clearing: true,
        person_id: selectedCustomer.selectedCustomer ? 0 : selectedCustomer.ID,
        person_code:
          selectedCustomer.selectedCustomer === null
            ? ""
            : selectedCustomer.code,
        status: 4,
      },
      header: [
        {
          text: "Code",
          value: "credit_notes.code",
        },
        {
          text: "Customer",
          value: "credit_notes.customer_name",
          // width: '5%'
        },
        {
          text: "Date",
          sortable: false,
          // width: '5%'
        },
        {
          text: "Value",
        },
      ],
      responseData: {},
    };
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("MM/DD/YYYY hh:mm");
      }
    },
    reloadData(params) {
      const newParams = { ...this.params };
      newParams.page = params.page;
      newParams.search = params.search;
      this.$vs.loading();

      const customerDeposit = this.getCustomerDeposit(newParams);
      customerDeposit.then((r) => {
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.responseData = r.data;
          this.responseData.length = r.data.length;
          this.responseData.recordsTotal = r.data.recordsTotal;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    checkboxHandle(id) {
      const cn = this.responseData.customerDeposits.find((cn) => cn.ID === id);

      const customerDeposits = JSON.parse(
        JSON.stringify(this.$store.state.arClearing.selectedCustomerDeposit)
      );
      if (customerDeposits.length === 0) {
        customerDeposits.push(cn);
        this.$store.commit("arClearing/setCustomerDeposit", customerDeposits);
        console.log(customerDeposits);
        return;
      }

      if (customerDeposits.some((cn) => cn.ID === id)) {
        const index = customerDeposits.findIndex((cn) => cn.ID === id);
        customerDeposits.splice(index, 1);
      } else {
        customerDeposits.push(cn);
      }
      this.$store.commit("arClearing/setCustomerDeposit", customerDeposits);
    },
    isChecked(id) {
      const customerDeposits =
        this.$store.state.arClearing.selectedCustomerDeposit;
      if (customerDeposits === 0) {
        return false;
      }
      console.log(customerDeposits.some((cn) => cn.ID === id));
      return customerDeposits.some((cn) => cn.ID === id);
    },
    getCustomerDeposit(params) {
      return this.$http.get("/api/v1/cash-bank/ar-clearing", {
        params,
      });
    },
    // checkAll() {
    //   this.$nextTick(() => {
    //     console.log("select All func", this.selectAll);
    //     if (this.selectAll) {
    //       console.log("all checked");
    //       this.selectedCreditNotes = [...this.responseData.creditNotes];
    //     } else {
    //       console.log("all unchecked");
    //       this.selectedCreditNotes = [];
    //     }
    //   });
    // },
  },
};
</script>
